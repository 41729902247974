/* Safari 9+, < 13.1 */
@media screen and (-webkit-min-device-pixel-ratio:0) {

  /* Safari only override */
  ::i-block-chrome,
  .cta-nav {

    border-radius: 0px !important;
  }
}



.form {
  padding: 25px;
  width: 400px !important;
}

.form h3 {
  font-size: 46px;
}

#contact mark {
  padding: 0.1875em;
  color: #84c599 !important;
  background-color: rgb(0, 0, 0) !important;
  font-style: italic;
}

mark {
  padding: 0.1875em;
  color: #212529 !important;
  background-color: rgb(132, 197, 153) !important;
  font-style: italic;
}

.swiper {
  overflow: hidden !important;
}

@font-face {
  font-family: "Rifton";
  /*Can be any text*/
  src: local("Rifton_Norm"),
    url("./fonts/Rifton_Norm.otf") format("opentype");
}

.navbar-toggler-icon {
  background-image: url('./assets/bread.png') !important;
}

/*SLIDER*/
.slide-bg {
  background-color: #84c599 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

}

.slide-pic {
  height: 200px;
}

.MyAwesomeParallax {
  overflow: hidden;
  margin: auto;

}

.link {
  cursor: pointer;
}

.error {
  color: red;
  font-size: 1rem;
  margin-top: 0.3rem;
}

.close {
  width: 30px;
  min-width: 30px !important;
  height: 30px;
  background-image: url('../public/assets/close.png');
  margin-top: 0px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute !important;
  cursor: pointer;
  background-color: #fff;
  box-shadow: none;
  padding: 0 !important;
  right: 8px;
  top: 5px;
  opacity: 0.7;
}


body {
  margin: 0;
  font-family: 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-dsfud9,
.css-80raif,
.MuiGrid2-root {
  --Grid-columnSpacing: 0px !important;
}

.footer .MuiGrid2-root {
  --Grid-columnSpacing: 12px !important;
}

.App {
  text-align: left;
}

.b-sce {
  background-color: #000;
  color: white;
}

.nav {
  background-color: #000;
  color: white;
  position: fixed;
  top: 0;
  z-index: 2;
  border-bottom: 2px solid white;
  opacity: 1;
}

.nav-link {
  color: white !important;
  text-transform: uppercase;
  font-family: "Rifton" !important;
}


.nav a {
  text-decoration: none;
}

.nav a:active,
.nav a:focus {
  color: white !important;
  text-decoration: underline 2px solid white !important;
}

.me-left {
  margin-left: auto !important;
}

.nav.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  transition: .15s ease-in-out;
  opacity: 1;
  z-index: 99;
}

.nav-cta {
  position: fixed;
  top: 80px;
  right: 20px;
  z-index: 99;
  opacity: 1;
}

.nav-cta.fixed {
  position: fixed;
  top: 80px;
  right: 20px;
  z-index: 99;
  display: none;
  transition: .15s ease-in-out;
  opacity: 0;
}

.cta-nav {
  font-size: 16px !important;
  padding: 15px !important;
  width: 160px;
  line-height: 25px !important;
  display: block;
  margin-right: 10px !important;
  background-color: #000 !important;
  border-radius: 69px !important;
  -webkit-border-radius: 69px;
  -moz-border-radius: 69px;
  font-family: 'Rifton' !important;
  color: #fbd33f !important;
  outline: 2px solid #fbd33f !important;
  outline-offset: -6px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

}

h3 {
  font-family: "Rifton" !important;
  font-size: 36px;
  padding-bottom: 20px;

}

.rif-txt {
  font-family: "Rifton" !important;
  text-transform: uppercase;
  font-size: 18px;
}

p {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
}

.hero-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.work {
  width: 100%;
  background-image: url('../public/assets/work_bg.jpg');
  margin-top: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  background-color: #000;
  background-position: center;
  padding-bottom: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 50px;

}

.contact {
  width: 100%;
  background-image: url('../public/assets/contact_bg.jpg');
  margin-top: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  background-color: #000;
  background-position: center;
  padding-bottom: 0px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 620px;

}

.hero {
  width: 100%;
  background-image: url('../public/assets/hero_bg.jpg');
  margin-top: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  background-color: #000;
  background-position: center;
  padding-bottom: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

}

.arrow {
  width: 80px;
  cursor: pointer;
  position: absolute;
  bottom: 50px;
  left: 47.5%;

}

@media (prefers-reduced-motion: no-preference) {
  .arrow {
    animation: ar-ani infinite 1.3s ease-in-out;
  }
}

@keyframes ar-ani {
  0% {
    bottom: 50px;
  }

  100% {
    bottom: 30px;
  }
}

.g-arrow {

  max-width: 320px;
  width: 100%;
  position: relative;
  padding: 50px;

}

@media (prefers-reduced-motion: no-preference) {
  .g-arrow {
    animation: g-ani infinite 1.3s ease-in-out;
  }
}

@keyframes g-ani {
  0% {
    bottom: 50px;
    left: 0px;
  }

  100% {
    bottom: 30px;
    left: 30px;
  }
}

.b-arrow {

  max-width: 320px;
  width: 100%;
  position: relative;
  padding: 50px;

}

@media (prefers-reduced-motion: no-preference) {
  .b-arrow {
    animation: b-ani infinite 1.3s ease-in-out;
  }
}

@keyframes b-ani {
  0% {
    bottom: 20px;
    right: 0px;
  }

  100% {
    bottom: 0px;
    right: 30px;
  }
}


.l-b-corner {
  position: absolute;
  bottom: 45px;
  left: 40px;
  width: 60px;
}

.l-t-corner {
  position: absolute;
  top: 45px;
  left: 40px;
  width: 60px;
}

.r-b-corner {
  position: absolute;
  bottom: 45px;
  right: 40px;
  width: 60px;
}

.r-t-corner {
  position: absolute;
  top: 45px;
  right: 40px;
  width: 60px;
}

.hero-box {
  height: 100px;
}

.hero-title {
  width: 100%;
  max-width: 971px;
  padding-top: 50px;
}

.hero-sub {
  width: 100%;
  max-width: 320px;
  padding-top: 30px;
}

.hero-copy {
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 24px;
  padding: 30px 0px 80px;
}

.hero-strip {
  width: 100%;
  display: block;
}

.HeroPad {
  padding-top: 30px;
  padding-bottom: 10px;
}

.pic-sec {
  padding: 0px !important;
}

.TopPad {
  padding-top: 0px;
  padding-bottom: 0px;
}

.NoPad {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ServicePad {
  padding-top: 0px;
  padding-bottom: 50px;
  position: relative;
}

.pmf-ex {
  padding-left: 0px;
}

.pmf {
  padding-left: 110px !important;
}

.pmf2 {
  text-align: center;
  color: #84c599 !important;
}

.pmf4 {
  padding-left: 0px;
  margin-left: 0px;
}

.hcenter {
  padding-top: 0px;
}

.copy-center {
  position: relative;
  align-self: center;
}

#contact button {
  background-color: #000 !important;
  border-radius: 69px !important;
  font-size: 32px !important;
  font-family: 'Rifton' !important;
  color: #fbd33f !important;
  padding: 20px 0px 25px !important;
  width: 300px;
  line-height: 40px !important;
  margin: 25px -15px -18px;
  outline: 2px solid #fbd33f;
  outline-offset: -10px;
  outline-style: auto;
  outline-style: solid;
}

.l-con-but {
  float: right;
}

.con-but {
  font-family: 'Rifton' !important;
  margin: auto !important;
  font-size: 26px !important;
  width: 250px;
  line-height: 30px !important;
  margin: 25px -15px 25px;
  background-color: #000 !important;
  border-radius: 69px !important;
  color: #fbd33f !important;
  padding: 25px 0px 30px !important;
  outline: 2px solid #fbd33f !important;
  outline-offset: -10px;

}

.what-but {
  background-color: #000 !important;
  border: 4px solid #84c599 !important;
  border-radius: 158px !important;
  padding: 55px 20px 20px !important;
  margin: 30px auto;
  text-align: center;
  width: 380px;
  line-height: 22px;
  height: 230px;
}

.what-left {
  margin-left: 25px;
}

.what-right {
  margin-right: 25px;
}

.what-but h3 {
  font-size: 32px !important;
  line-height: 28px;
  color: #84c599;
}

.what-but p {
  font-size: 18px !important;
  font-weight: 300;
  line-height: 18px;
}



.footer {
  border-top: 2px solid white;
  grid-row-gap: 6px;
  flex-direction: column !important;
  align-items: center !important;
  display: flex;
  text-align: center !important;
  padding-top: 60px;
  padding-bottom: 40px;

}

.footer-links {
  font-size: 18px;
}

.footer a {
  text-decoration: none;
  color: #fff;
}

.footer a:active,
.footer a:focus,
.footer a:hover {
  color: white !important;
  text-decoration: underline 1px solid white !important;
}

.footer li {
  display: block;
  cursor: pointer;
}

.but-stack {
  margin: auto;
  width: 45% !important;
}

.swiper-new {
  border-radius: 130px;
  overflow: hidden;
  height: 100%;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  content: '' !important;
}

.swiper-button-prev {
  width: 60px !important;
  height: 60px !important;
  background-image: url('./assets/icon-arrow-left.png') !important;
  background-size: 60px 60px !important;
}

.swiper-button-next {
  width: 60px !important;
  height: 60px !important;
  background-image: url('./assets/icon-arrow-right.png') !important;
  background-size: 60px 60px !important;
}

.swiper-pagination-bullet {
  background: #fff !important;
}

.mob {
  display: none !important;
}

.std {
  display: block !important;
}

@media (max-width: 1400px) {



  .pmf {
    padding-left: 80px !important;
  }


}


@media (max-width: 1200px) {

  .what-left {
    margin-left: auto;
  }

  .what-right {
    margin-right: auto;
  }

  .pmf {
    padding-left: 60px !important;
  }

  #contact button {
    font-size: 28px !important;
    font-family: 'Rifton' !important;
    width: 250px;
    line-height: 35px !important;
  }

  .ServicePad {
    padding-top: 0px;
    padding-bottom: 50px;
    position: relative;
  }
}

@media (max-width: 1024px) {
  .hcenter {
    padding-top: 30px;
  }

  .hero-copy {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 24px;
    padding: 30px 0px 10px;
  }

  .hero-title {
    width: 100%;
    max-width: 971px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .but-stack {
    margin-right: 10px;
    width: 45% !important;
  }

  .hero-box {
    height: 80px;
  }

  .swiper-new {
    border-radius: 30px;
  }

  #contact button {

    font-size: 26px !important;
    width: 250px;
    line-height: 30px !important;
    margin: 25px -15px 25px;

  }

  .pmf {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

}

@media (max-width: 740px) {

  .but-stack {
    margin-right: 20px;
    width: 49%;
  }

  .pmf,
  .pmf3 {
    padding-left: 30px !important;
  }

  .pmf4 {
    padding-left: 0px;
    margin-left: 0px !important;
  }

  .swiper-new {
    border-radius: 30px;

  }

  .hero-box {
    height: 50px;
  }

  .hcenter {
    padding-top: 0px;
  }

  .TopPad {
    padding-top: 70px;
  }

  .ServicePad {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  #contact button {

    font-size: 26px !important;
    padding: 17px !important;
    width: 190px;
    line-height: 30px !important;

  }

}


@media (max-width: 470px) {

  /* html {
    scroll-snap-type: y mandatory;
    transition: 5000ms ease-in;
  }

  #hero,
  #work,
  #what,
  #contact {
    scroll-snap-align: start;
    scroll-snap-stop: always;

  }*/

  .slide-pic {
    height: 100px;
  }

  .form {
    padding: 25px !important;
    width: 340px !important;
  }

  .form h3 {
    font-size: 36px !important;
  }

  mark {
    padding: 0.1875em;
    color: #ffffff !important;
    background-color: rgb(0, 0, 0) !important;
    font-style: normal;
    padding: 0px !important;
  }

  .nav-cta {
    display: none;
  }

  br {
    display: none;
  }

  .what-but br {
    display: block;
  }

  .footer br {
    display: block;
  }

  p {
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 18px;
    line-height: 28px;
  }


  h3 {
    text-align: center;
    font-size: 44px !important;
  }

  ul {
    text-align: center;
  }

  .nav {
    top: 0vh;
  }

  #contact button {
    font-size: 16px !important;
    padding: 15px !important;
    width: 160px;
    line-height: 25px !important;
    display: block;
    margin: auto;
  }

  .pmf {
    padding-left: 0px !important;
    padding-right: 15px !important;
  }

  .pmf3 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .pmf-ex {
    padding-left: 15px !important;
  }

  .but-stack {
    margin-right: 0px;
    width: 50% !important;
  }

  .l-con-but {
    margin-right: 10% !important;
  }

  .hcenter {
    padding-top: 0px;
  }

  .arrow {
    width: 30px;
  }

  .hero-box {
    height: 75px;
  }

  .hero-copy {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 24px;
    padding: 30px 0px 70px;
  }

  .hero-title {
    width: 100%;
    max-width: 971px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .hero-sub {
    width: 100%;
    max-width: 180px;
    padding-top: 10px;
  }

  .TopPad {
    padding-top: 30px;
  }

  .ServicePad {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .hero-strip {
    width: 100%;
    display: none;
  }

  .swiper-new {
    border-radius: 30px;

  }

  .footer-links {
    font-size: 18px;
  }

  .what-but {
    border-radius: 158px !important;
    margin: 30px auto;
    text-align: center;
    width: 340px;
    line-height: 22px;
    background-color: #000 !important;
    border: 4px solid #84c599 !important;
    padding: 55px 20px 20px !important;
    height: 230px;
  }

  .swiper-button-prev {
    width: 30px !important;
    height: 30px !important;
    background-image: url('./assets/icon-arrow-left.png') !important;
    background-size: 30px 30px !important;
  }

  .swiper-button-next {
    width: 30px !important;
    height: 30px !important;
    background-image: url('./assets/icon-arrow-right.png') !important;
    background-size: 30px 30px !important;
  }

  .mob {
    display: block !important;
  }

  .std {
    display: none !important;
  }

  .work {
    padding: 20px;
  }

  .contact {
    height: 490px;
  }
}

@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .pmf4 {
      margin-left: 30px !important;
      padding-left: 0;
    }
  }
}

@supports (-ms-ime-align:auto) {
  .pmf4 {
    margin-left: 30px !important;
    padding-left: 0;
  }
}